import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { createSelector } from 'reselect';

import { auth, scene, scenes, permission, tutorial, sceneFeature, payment, dealer } from 'reducers';
import { AppState } from 'types/store';
import { PermissionFeaturesMap } from 'types/permission';
import PLATFORM_KEYS from 'constants/platform';
import { PaymentInfo } from 'types/creditCard';

const rootReducer = combineReducers({
    auth,
    scene,
    scenes,
    permission,
    tutorial,
    sceneFeature,
    payment,
    dealer,
});

const middleWareEnhancer = applyMiddleware(thunk);

const store = createStore(rootReducer, middleWareEnhancer);

export default store;

const reduceMapById = <T extends { id: string }>(data: T[]) =>
    data.reduce(
        (map, item) => ({
            ...map,
            [item.id]: item,
        }),
        {} as { [id: string]: T },
    );

export const getBooths = (store: AppState) => store.scene?.booths || [];
export const getEditGroups = (store: AppState) => store.scene?.editGroups || [];
export const getAreas = (store: AppState) => store.scene?.groupAreas || [];

export const getEditGroupsMap = createSelector(getEditGroups, reduceMapById);

export const getGroupAreaMap = createSelector(getAreas, reduceMapById);

export const getBoothsMap = createSelector(getBooths, reduceMapById);

export const getAreasMap = createSelector(getAreas, reduceMapById);

export const getFeaturesMap = createSelector(
    (store: AppState) => store.permission.features,
    (features) =>
        features.reduce(
            (map, feature) => ({
                ...map,
                [feature.name]: true,
            }),
            {} as PermissionFeaturesMap,
        ),
);

export const getPackageInfo = createSelector(
    (store: AppState) => store.payment.content,
    (packages) => {
        const currentPackage = packages.find(
            (content) => content.mainPackage === true && content.productId === PLATFORM_KEYS.productId,
        );
        const packageInfo: Partial<PaymentInfo> = currentPackage || {
            productId: '',
            name: '',
            expiredAt: '',
            type: '',
            id: '',
        };
        return packageInfo;
    },
);

const PREMIER_PLAN = 'super package';

export const isPremierPlan = createSelector(
    (store: AppState) => store.permission.name,
    (name) => name.toLowerCase() === PREMIER_PLAN,
);
